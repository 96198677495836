import React, { useContext } from "react";
import { filter, toArray } from "lodash";
import { Link, withRouter } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import UserContext from "../../contexts/UserContext";
import OfficeContext from "../../contexts/OfficeContext";
import { MenuItems } from "../../NavigationItems";
import { FontIcon } from "@fluentui/react";
import { SessionContext } from "../../contexts/SessionContext";
import { PluginsContext } from "../../contexts/PluginsContext";

export const TopNavigationOfficeContainer = styled.div`
  display: flex;
  align-self: stretch;

  ul {
    list-style: none;
    align-items: flex-start;
    flex-direction: row;
    height: 35px;
    display: flex;
    flex: 1;
    font-size: 1.1rem;

    li {
      a {
        width: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: #94979e;

        span {
          font-size: 0.8rem;
          font-weight: normal;
          text-align: center;
          break-inside: avoid-page;
          opacity: 0;
          display: none;
          animation: ease-in;
          transition-duration: 200ms;
        }

        &:hover {
          color: #0751b8;

          span {
            opacity: 1 !important;
            display: block;
            margin-top: 0;
          }
        }
      }

      &.active {
        a {
          color: #0751b8;
          span {
            opacity: 1;
            display: block;
          }
        }
      }
    }
  }
`;

export const NavbarItem = (props) => {
  const { comp } = props;

  return (
    <li key={comp.key} className={`${comp.key === "floborocks" ? "active" : ""}`} css={{ ...(props.styles || {}) }}>
      {comp.url && (
        <a
          href={""}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(comp.url);
          }}
        >
          {typeof comp.icon() === "string" ? <FontIcon iconName={comp.icon()} /> : comp.icon()}
          <span>{comp.name}</span>
        </a>
      )}

      {!comp.url && (
        <Link to={`/${comp.route || comp.key}`}>
          <FontIcon iconName={comp.icon()} />
          <span>{comp.name}</span>
        </Link>
      )}
    </li>
  );
};

const generateMenu = () => {
  if (MenuItems) {
    return toArray(filter(MenuItems, "top"));
  }
};

export const Navbar = ({ history, currentComponent }) => {
  const { organisation } = React.useContext(SessionContext);
  const [items, setItems] = React.useState([]);
  const { PluginRender } = React.useContext(PluginsContext);

  React.useEffect(() => {
    setItems(generateMenu());
  }, [organisation]);

  return (
    <TopNavigationOfficeContainer>
      <ul>
        {items.map((comp, key) => {
          return <NavbarItem comp={comp} key={key} />;
        })}

        <PluginRender scope={"navbar.items"} component={NavbarItem} />

        <NavbarItem
          comp={{ name: "Settings", route: "settings", icon: () => "Settings" }}
          key={"settings"}
          styles={css`
            margin-left: auto;
          `}
        />
      </ul>
    </TopNavigationOfficeContainer>
  );
};

export default withRouter(Navbar);
