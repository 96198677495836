import React from "react";
import { TextButton } from "../../UI";
import { withRouter } from "react-router-dom";
import { css } from "@emotion/react";

export const ActionsbarItemsContext = React.createContext();

/**
 *
 * Allows to define actions for a main app actions ribbon (top menu)
 *
 * @param history
 * @returns {string|*}
 * @constructor
 */
const Actionsbar = ({ history }) => {
  const { actionbarItems } = React.useContext(ActionsbarItemsContext);

  if (actionbarItems) {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        {actionbarItems &&
          actionbarItems.map((item, i) => (
            <TextButton
              styles={{
                root: { color: "#005A9E" },
                selectors: {
                  i: { fontSize: "0.8rem" },
                },
              }}
              key={i}
              onClick={() => (item.url ? history.push(item.url) : item.action ? item.action() : false)}
              label={item.label}
              icon={item.icon}
            />
          ))}
      </div>
    );
  }
  return "";
};

export default withRouter(Actionsbar);
