import React, { createContext } from "react";

export const OfficeContext = createContext({});

export const OfficeProvider = ({ children }) => {
  const [office, setOffice] = React.useState(false);

  return (
    <OfficeContext.Provider
      value={{
        getOffice: () => office,
        setOffice: setOffice,
      }}
    >
      {children}
    </OfficeContext.Provider>
  );
};
export default OfficeContext;
