import React from "react";
import { Button, Container, Header3, Progress, TextButton } from "../../UI";
import { Section } from "../../UI/styled";
import { itemAddInfo, loadMessageBody } from "../../helpers/Office.helper";
import { FontIcon, StackItem, TextField } from "@fluentui/react";
import { OfficeContext } from "../../contexts/OfficeContext";
import styled from "@emotion/styled";
import LoginView from "./LoginView";
import { FirebaseContext } from "../../firebase/FirebaseProvider";
import { isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from "firebase/auth";

const LoginColumn = styled(Section)`
  flex: 1;
  align-items: center;
  max-width: 500px;
  min-width: 290px;
  font-size: 1.1em;

  button {
    margin-left: 0;
    margin-top: 15px;
  }
`;

const LoginContent = styled.div`
  max-width: 400px;
  min-width: 270px;
  padding: 5px;
`;

/**
 *
 * scenarios
 * 1. clean slate: no requested login link, no errors
 * 2. login link sent, waiting (emailSent)
 * 3. link arrived: - token present
 *                  - email used exist: offer to login / automatically login
 *                  - email used doesnt exist - show email input field
 * 4. error: link expired / not working /whatever
 *    - show error message, offer to resend email
 *
 *
 *
 *
 * @returns {*}
 * @constructor
 */

export const Login = () => {
  const [emailSent, setEmailSent] = React.useState(false);
  const [confirmEmail, setConfirmEmail] = React.useState("");
  const [authTokenUrl, setAuthTokenUrl] = React.useState(null);
  const [authTokenInvalid, setAuthTokenInvalid] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [error, setError] = React.useState("");
  const { isOffice } = React.useContext(OfficeContext);
  const [authenticating, setAuthenticating] = React.useState(false);
  const { auth } = React.useContext(FirebaseContext);
  const [signInByUserProfile] = React.useState((Office.context.mailbox && Office.context.mailbox.userProfile) || false);

  /*
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  */

  React.useEffect(() => {
    console.log(isOffice);
    if (isOffice) {
      // force using the users account email address
      setConfirmEmail(Office.context.mailbox.userProfile.emailAddress);

      // watch out for sign in email...
      Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, () => {
        checkMessageForAuthToken(Office.context.mailbox.item);
      });

      if (Office.context.mailbox.item) {
        checkMessageForAuthToken(Office.context.mailbox.item);
      }

      return () => {
        if (isOffice) {
          Office.context.mailbox.removeHandlerAsync(Office.EventType.ItemChanged);
        }
      };
    } else {
      // checking URL for sign-in token for outside office scenarios
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // user has to enter email for confirmation,
        // setting the auth token will cause the app to show the email confirmation screen
        setAuthTokenUrl(window.location.href);
      }
    }
  }, []);

  // look into the current email to find the login token
  const checkMessageForAuthToken = async (message) => {
    console.log("checking email for auth token");
    const body = await loadMessageBody(message, "Html");
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(body, "text/html");
    const list = htmlDoc.getElementsByTagName("a");
    setAuthTokenInvalid(false);

    for (let link of list) {
      if (link.innerText.replace(/\s|\n|\r/g, "") === "SignintoInContext" && link.href.indexOf("apiKey") > 0) {
        console.log("found link", link.href);

        // process link as Outlook can block html links by rewriting them
        if (link.href.indexOf("url=") > -1) {
          console.log("url=");
          link.href = unescape(link.href.split("url=")[1]);
          console.log(link.href);
        }

        if (isSignInWithEmailLink(auth, link.href)) {
          let authToken = link.href;
          console.log("link is valid");
          // as we have the token, lets sign in
          signinWithToken(authToken, Office.context.mailbox.userProfile.emailAddress);
        } else {
          console.log("link not valid");
          setAuthTokenUrl(false);
          setAuthTokenInvalid(true);
        }

        break;
      }
    }
  };

  // if token present, try to sign in with it
  const signinWithToken = (url, email) => {
    if (typeof confirmEmail !== "string") {
      return;
    }

    setError("");
    setAuthenticating(true);

    signInWithEmailLink(auth, email || confirmEmail, url || authTokenUrl)
      .then((r) => {
        console.info("login successful ", r);
        window.localStorage.removeItem("EmailSent");

        if (Office.context && Office.context.mailbox && Office.context.mailbox.item) {
          itemAddInfo(Office.context.mailbox.item, "Login link used on " + new Date().toLocaleString(), "used");
        }

        //setApp({ ...app, justLoggedIn: true });
      })
      .catch((e) => {
        setError(e.message);
        setAuthTokenInvalid(true);
        setEmailSent(false);
        console.error("Error signing in with Email Link", e);
      })
      .finally(() => {
        setAuthenticating(false);
      });
  };

  const sendLoginLink = (emailAddress) => {
    setSending(true);
    setError("");

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: window.location.origin,
      // This must be true.
      handleCodeInApp: true,
    };

    try {
      sendSignInLinkToEmail(auth, emailAddress, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailSent", emailAddress);
          setEmailSent(emailAddress);
          setTimeout(() => {
            setSending(false);
          }, 10000);
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          setError(error.message);
          console.error("Error sending log-in link: ", error);
        });
    } catch (e) {
      console.error("Error sending email link: ", e);
    }
  };

  const resendEmailSent = () => {
    if (Office && Office.context && Office.context.mailbox) {
      sendLoginLink(Office.context.mailbox.userProfile.emailAddress);
      return;
    }

    if (emailSent) {
      sendLoginLink(emailSent);
      return;
    }

    setEmailSent(false);
    setConfirmEmail(false);
  };

  if (authenticating) {
    return (
      <Container>
        <LoginColumn cy="loginbox">
          <Progress showSpinner={sending} message={"Sending"} />
          <LoginContent>
            <StackItem>
              <h4>Login Email found</h4>
              <p>Authenticating ....</p>
            </StackItem>
          </LoginContent>
        </LoginColumn>
      </Container>
    );
  }

  if (authTokenUrl) {
    return (
      <Container>
        <LoginColumn cy="loginbox">
          <Progress showSpinner={sending} message={"Sending"} />
          <LoginContent>
            {authTokenInvalid && (
              <div>
                <h3>Login email expired</h3>
                <p>
                  This login email is no longer valid because it is either expired or has been used already. Please
                  delete this email. If you want to login again, please request a new Login email by pressing the button
                  below.
                </p>

                <Button
                  primary={false}
                  styles={{
                    root: {
                      width: "100%",
                      textAlign: "center",
                      padding: "15px",
                    },
                  }}
                  data-cy="send-button"
                  onClick={() => {
                    setAuthTokenUrl(null);
                    setAuthTokenInvalid(null);
                    resendEmailSent();
                  }}
                >
                  Request login link
                </Button>
              </div>
            )}

            {emailSent && !authTokenInvalid && (
              <>
                <h3>Login email found</h3>
                <p>This looks like a genuine InContext login email. Please click the login link below.</p>
                <Button
                  primary={true}
                  onClick={signinWithToken}
                  disabled={sending}
                  data-cy="login-button"
                  styles={{
                    root: {
                      width: "100%",
                      textAlign: "center",
                      padding: "15px",
                    },
                  }}
                >
                  Login to InContext
                </Button>
              </>
            )}

            {authTokenUrl && !emailSent && !authTokenInvalid && (
              <>
                <Header3>Login link found</Header3>
                <p>Please enter your email address that you used to request the Login link.</p>
                <TextField
                  data-cy="email-input"
                  type="email"
                  label="Email address"
                  value={
                    Office && Office.context && Office.context.mailbox.userProfile.emailAddress
                      ? Office.context.mailbox.userProfile.emailAddress
                      : confirmEmail
                  }
                  onChange={(e) => setConfirmEmail(e.target.value)}
                />

                <Button
                  primary={true}
                  onClick={() => signinWithToken(authTokenUrl, confirmEmail)}
                  disabled={sending}
                  styles={{
                    root: {
                      width: "100%",
                      textAlign: "center",
                      padding: "15px",
                    },
                  }}
                >
                  Login
                </Button>
              </>
            )}
          </LoginContent>
        </LoginColumn>
      </Container>
    );
  }

  if (emailSent) {
    return (
      <LoginView>
        <Container>
          <LoginColumn cy="loginbox">
            <LoginContent>
              <h3>Login email underway!</h3>
              {isOffice && (
                <p>
                  This could take a few moments to arrive. <br /> <br />
                  Once you select that email, InContext will automatically detect it and connect. Please dont click any
                  link in that email.
                  <br /> <br />
                  This may be a good moment to check if you have pinned the panel by clicking the pin icon{" "}
                  <FontIcon iconName="Pin" /> to make sure the panel stays open.
                  <br />
                </p>
              )}
              {!isOffice && (
                <p>
                  Please open that email in your Outlook which you want to connect to InContext and follow the
                  instructions to get started.
                </p>
              )}
              {!sending && (
                <TextButton
                  onClick={resendEmailSent}
                  styles={{
                    root: {
                      width: "100%",
                      textAlign: "center",
                      padding: "15px",
                    },
                  }}
                  data-cy="resend-button"
                  disabled={sending}
                >
                  Resend login link
                </TextButton>
              )}
            </LoginContent>
          </LoginColumn>
        </Container>
      </LoginView>
    );
  }

  return (
    <LoginView>
      <Container>
        <LoginColumn cy="loginbox">
          <LoginContent>
            {signInByUserProfile && <h3>Hi {Office.context.mailbox.userProfile.displayName.split(" ")[0]}!</h3>}

            {authTokenInvalid && (
              <>
                <p>
                  This login email is no longer valid because it is either expired or has been used already. Please
                  delete this email. If you want to login again, please request a new Login email by pressing the button
                  below.
                </p>
              </>
            )}

            {signInByUserProfile && (
              <>
                {!authTokenInvalid && (
                  <>
                    <p>
                      We think InContext is the fastest and most intuitive way to save emails and attachments to
                      SharePoint.
                    </p>
                    <p>
                      To start, click the button below to connect your Inbox to InContext. An email with a login link
                      will be sent to <b>{Office.context.mailbox.userProfile.emailAddress}</b>
                    </p>
                    <p>
                      Please make sure to pin the panel by clicking on the <FontIcon iconName="Pin" /> icon at the top
                      of the panel, so it stays open all the time.
                    </p>
                  </>
                )}
              </>
            )}

            {!signInByUserProfile && (
              <>
                <h3>Connect InContext</h3>
                <p>Great to see you and thank you for using InContext.</p>

                <p>
                  To start, click the button below to connect your Inbox to InContext. An email with a login link will
                  be sent to your email address.
                </p>

                <TextField
                  type="email"
                  label="Email address"
                  data-cy="login-input"
                  value={confirmEmail}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                />
              </>
            )}

            <Button
              primary
              data-cy="login-button"
              onClick={() => sendLoginLink(confirmEmail || Office.context.mailbox.userProfile.emailAddress)}
              disabled={sending}
            >
              {sending ? "sending ... " : "Request Login Link"}
            </Button>
          </LoginContent>
        </LoginColumn>
      </Container>
    </LoginView>
  );
};
