import React from "react";
import { getFirestore } from "firebase/firestore/lite";
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { Progress } from "../UI";

export const FirebaseContext = React.createContext({});
export const FirebaseProvider = ({ config, children }) => {
  const app = initializeApp(config);

  const [user, setUser] = React.useState(undefined);
  const [db] = React.useState(getFirestore(app));
  const [auth] = React.useState(getAuth);

  React.useEffect(() => {}, []);

  auth.onAuthStateChanged((user) => {
    /// , User, UserInfo, UserProfile
    setUser(user);
  });

  if (typeof user === "undefined") {
    return <Progress />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        app,
        auth,
        db,
        user,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const AuthCheck = ({ fallback, children }) => {
  const { auth } = React.useContext(FirebaseContext);
  auth.onAuthStateChanged((user) => {
    console.log({ ...user });
  });

  if (auth.currentUser) {
    return children;
  } else {
    return fallback;
  }
};
