import { FontWeights, FontSizes, getTheme, IconButton, mergeStyleSets, Modal as FluentModal } from "@fluentui/react";
import React from "react";
import { Button } from "./Button";

const theme = getTheme();

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    maxWidth: "calc(100% - 250px)"
  },
  header: [
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semilight,
      padding: "12px 12px 14px 24px",
      fontSize: FontSizes.xLarge
    }
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0", maxWidth: "70%" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 }
    }
  },

  actions: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 }
    }
  }
});

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px"
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};

export const Modal = props => {
  const [isModalOpen, setIsModalOpen] = React.useState(props.config.show || false);
  const { title = "Modal title, set with props.title" } = props.config;

  const {
    actions = false, // React Element
    actionButtons = false, // Array of buttons
    onConfirm = false,
    onDismiss = () => {
      console.log("modal props.onDismiss() called");
    }
  } = props;

  React.useEffect(() => {
    setIsModalOpen(props.config.show);
  }, [props.config]);

  const dismissModal = () => {
    setIsModalOpen(false);
    onDismiss();
  };

  return (
    <FluentModal
      titleAriaId={"modal"}
      isOpen={isModalOpen}
      onDismiss={dismissModal}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span>{title}</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={{ iconName: "Cancel" }}
          ariaLabel="Close modal"
          onClick={dismissModal}
        />
      </div>

      <div className={contentStyles.body}>{props.children}</div>
      {actionButtons && (
        <div className={contentStyles.actions}>
          {actionButtons.map((b, k) => (
            <Button key={k} primary={b.primary} />
          ))}
        </div>
      )}

      {(actions || onConfirm || onCancel) && (
        <div className={contentStyles.actions}>
          {actions}
          {onConfirm && <Button text="Ok" onClick={() => onConfirm()} primary />}
          {onDismiss && (
            <Button text="Cancel" onClick={() => (typeof onDismiss === "function" ? onDismiss() : dismissModal())} />
          )}
        </div>
      )}
    </FluentModal>
  );
};
