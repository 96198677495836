import React from "react";
import { Heading } from "./Heading";

export const Container = ({ collapse = false, heading = false, children, attr = false, styles = {} }) => {
  const _styles = React.useMemo(() => {
    return {
      ...{
        boxSizing: "border-box",
        maxWidth: "450px",
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        width: "100%",
        minWidth: "100%",
        maxHeight: "100%",
        flex: collapse ? 0 : 1,
        padding: "0.6rem",
        ul: {
          listStyle: "none",
        },
      },
      ...styles,
    };
  }, [attr.styles]);

  return (
    <div {...attr} css={_styles}>
      <>
        {heading && <Heading>{heading}</Heading>}

        {children}
      </>
    </div>
  );
};
