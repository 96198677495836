import { Header1 } from "./Heading";
import { css } from "@emotion/react";
import React from "react";

export const InContextHeader = () => {
  return (
    <Header1
      styles={css`
        background-image: url("/assets/header.jpg");
        background-color: #efefef;
        align-self: stretch;
        justify-items: end;
        display: flex;
        margin: 0;
        gap: 0;
        min-width: 100%;
        height: 150px;
        margin-bottom: 15px;
        background-size: cover;
        align-items: flex-start !important;
        width: calc(100% + -10px);
      `}
    >
      <div
        css={css`
          margin: 20px;
          width: 10px;
        `}
      >
        Superfast SharePoint filing
      </div>
    </Header1>
  );
};
