import React from "react";
import { Shimmer as MSShimmer, ShimmerElementType } from "@fluentui/react";

/**
 @param  {Integer} lines=1 - number of lines to be created
 @param  {Boolean} [withIcons=false] - create a circle at the beginning of the row
 @param  {Object} [styles] - additional css styles
 @param {Integer} [height=16] - height of shimmer
 @param {Integer } [iconHeight=24] - height of icon pill
 @param {Array} [segments=100] - list of segments of shimmer, ever even segment is a gap
 @param {Boolean} [randomizeLast=true] - randomize length of last segment
 @param {Integer} [minlength] - minimum length of randomized last segment in percent
 */
export const Shimmer = ({
  lines = 1,
  withIcons = false,
  styles = {},
  height = 16,
  iconHeight = 24,
  segments = [100],
  randomizeLast = true,
  minlength = 50
}) => {
  const _styles = {
    ...{
      padding: 2,
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
      "& > .ms-Shimmer-container": {
        margin: "10px 0"
      },
      backgroundColor: "#FFF",
      ...styles
    }
  };

  let rows = [];

  // stack up the lines
  for (let i of Array(lines)) {
    i++;

    // create a new shimmer row
    let row = [];
    let total = 0;

    // add optional icon circle
    if (withIcons) {
      row.push({ type: ShimmerElementType.circle, height: iconHeight }, { type: ShimmerElementType.gap, width: "5%" });
    }

    // add each segment to row
    segments.map((e, i) => {
      // if randomised length of last segment
      if (i === segments.length - 1 && randomizeLast) {
        minlength = Math.min(minlength, 100 - total); // min length cant be larger than rest of 100
        e = (e - minlength) * Math.random() + minlength;
      }
      row.push({ type: ShimmerElementType[i % 2 ? "gap" : "line"], height: height, width: String(e) + "%" });

      total += e; // count total length

      if (i === segments.length - 1 && total < 100) {
        row.push({ type: ShimmerElementType.gap, width: String(100 - total) + "%" });
      }
    });

    rows.push(row);
  }

  return (
    <div css={_styles}>
      {rows.map((r, k) => (
        <MSShimmer key={k} shimmerElements={r} />
      ))}
    </div>
  );
};
