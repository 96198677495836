import { keys } from "lodash";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore/lite";
import { getAuth } from "firebase/auth";

export default class GenericDB {
  constructor(db, collectionPath) {
    this.db = db;
    this.collectionPath = collectionPath;
    this.auth = getAuth(db.app);
  }

  currentUser = () => {
    const _currentUser = this.auth.currentUser;
    return { uid: _currentUser.uid, displayName: _currentUser.displayName, photoURL: _currentUser.photoURL };
  };

  /**
   * Create a document in the collection
   * @param data
   * @param id
   */
  async create(data, id = false) {
    // remove reference from data if exists
    if (data.ref) {
      delete data.ref;
    }

    const dataToCreate = {
      ...data,
      createTimestamp: serverTimestamp(),
      updateTimestamp: serverTimestamp(),
    };

    const docRef = id
      ? await setDoc(doc(this.db, this.collectionPath, id), dataToCreate)
      : await addDoc(collection(this.db, this.collectionPath), dataToCreate);

    console.log("Doc Ref: ", docRef);

    const docId = id || docRef.id;

    return {
      ...data,
      id: docId,
      ref: docRef,
      createTimestamp: new Date(),
      updateTimestamp: new Date(),
    };
  }

  /**
   * Read a document in the collection
   * @param id
   */
  async read(id) {
    const docRef = await doc(this.db, `${this.collectionPath}/${id}`);
    return getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          let data = docSnap.data();
          this.convertObjectTimestampPropertiesToDate(data);
          return { ...data, id, ref: docSnap };
        } else {
          console.log("Doc doesnt exist");
          return false;
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
        return false;
      });
  }

  /**
   * Read all documents in the collection following constraints
   * @param constraints
   * @param order
   */
  async readAll(constraints = null, order = null) {
    let query = (await firestore()).collection(this.collectionPath);

    if (constraints) {
      constraints.forEach((constraint) => {
        query = query.where(...constraint);
      });
    }

    const formatResult = (result) =>
      result.docs.map((ref) =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data(),
        })
      );

    if (order) {
      if (order.orderBy) {
        query.orderBy(order.orderBy);
      }

      if (order.orderByChild) {
        query.orderByChild(order.orderByChild);
      }
    }

    return query.get().then(formatResult);
  }

  async subscribeCollection(callback, constraints = null) {
    let query = (await firestore()).collection(this.collectionPath);
    if (constraints) {
      constraints.forEach((constraint) => {
        query = query.where(...constraint);
      });
    }

    const formatResult = (data) =>
      this.convertObjectTimestampPropertiesToDate({
        id: data.id,
        ...data.data(),
      });

    return query.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach(function (change) {
        callback(formatResult(change.doc), change.type, change);
      });
    });
  }

  /**
   * Update a document in the collection
   * @param data reference or data object with id to update
   * @param changes the data the data to update
   */
  async update(_id, changes) {
    console.log(_id);

    const id = typeof _id === "string" ? _id : _id.id;
    const { ref, _user, ...payload } = {
      ...changes,
      _user: this.currentUser(), // add user as server function doesnt have access to requesting auth data
      audit: true, // tell server function to audit this update
    };

    console.log("Updating ", this.collectionPath, payload);

    const result = await updateDoc(doc(this.db, this.collectionPath, id), payload);

    return { ...payload, id, result };
  }

  /**
   * Delete a document in the collection
   * @param id
   */
  async delete(id) {
    return await deleteDoc(doc(this.db, `${this.collectionPath}`, id));
  }

  async deleteCollection(id, tree) {
    //return await deleteDoc(doc(this.db, `${this.collectionPath}`, id));
  }

  /**
   * Convert all object Timestamp properties to date
   * @param obj
   */
  convertObjectTimestampPropertiesToDate(obj) {
    try {
      const newObj = {};
      keys(obj)
        .filter(
          (prop) =>
            obj[prop] instanceof Object &&
            !obj[prop] instanceof DocumentReference &&
            !obj[prop] instanceof CollectionReference
        )
        .forEach((prop) => {
          if (obj[prop] instanceof Timestamp) {
            newObj[prop] = obj[prop].toDate();
          } else {
            this.convertObjectTimestampPropertiesToDate(obj[prop]);
          }
        });

      return {
        ...obj,
        ...newObj,
      };
    } catch (e) {
      console.log("error converting timestamp to date");
    }
  }
}
