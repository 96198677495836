import { Button } from "./Button";
import React from "react";
import { loginRequest, msalConfig } from "../config/msalConfig";
import { MsalContext, useIsAuthenticated } from "@azure/msal-react";
import { showDialog, signInO365 } from "../helpers/fallbackauthhelper";
import OfficeContext from "../contexts/OfficeContext";
import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);
const handleMSALLoginRedirect = (instance) => {
  instance
    .loginRedirect(loginRequest)
    .then((r) => {
      console.log("loginRedirect result ");
      console.log(r);
    })
    .catch((e, error) => {
      console.log("loginRedirect error", e, error);
    });
};

const handleMSALSSOSilent = async (instance) => {
  try {
    let request = {
      ...loginRequest,
      login_hint: Office.context.mailbox.userProfile.emailAddress,
    };
    const r = await instance.ssoSilent(loginRequest);
  } catch (e) {
    if (e.indexOf("interaction_required") > -1) {
      console.log("loginRedirect error", e);
      handleMSALLoginRedirect();
    }
  }
};

const handleMSALLoginDialogue = async (instance) => {
  console.log(msalInstance.getAllAccounts());
  console.log(msalInstance.getActiveAccount());
  console.log(window.origin);

  return new Promise((success, error) => {
    signInO365(
      (msg) => {
        console.log("State ", msg);
      },
      (token) => {
        if (token === "session_exists") {
          instance
            .ssoSilent()
            .then((r) => console.log(r))
            .catch((e) => console.log("ERROR: ", e));
        }
        console.log("Token: ", token);
        success(token);
        return token;
      },
      (e) => {
        console.log("Error", e);
        error(e);
        return e;
      }
    );
  });
};

const handleMSALLogoutDialogue = async (instance) => {
  return new Promise((success, error) => {
    showDialog(
      (m) => {
        console.log(m);
        success(m);
      },
      (event) => {
        console.log(event);
        success(event);
      },
      "/fallbacklogout.html"
    );
  });
};

export const MicrosoftLoginButton = ({ styles = {} } = props) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = React.useContext(MsalContext);
  const { refreshMsalInstance } = React.useContext(OfficeContext);
  const { officeHost } = React.useContext(OfficeContext);

  React.useEffect(() => {
    console.log("MSAL STATUS CHANGED BUTTON ", isAuthenticated);
  }, [isAuthenticated]);

  const onLoginClicked = async () => {
    try {
      /*
      switch (officeHost.platform) {
        default:
          await instance.loginPopup(loginRequest);
          break;

        case "PC":
          await handleMSALLoginDialogue(instance);
          break;
      }
      
       */
      console.log(instance);
      await handleMSALLoginDialogue(instance);

      console.log(instance.getAllAccounts());
      console.log(instance.getActiveAccount());

      instance.setActiveAccount(instance.getAllAccounts()[0]);
      refreshMsalInstance();
    } catch (e) {
      console.log("Error Logging in: ", e);
    }
  };

  const onLogoutClicked = async () => {
    if (!isAuthenticated) {
      return;
    }

    try {
      switch (officeHost.platform) {
        default:
          await instance.logoutPopup();
          break;

        case "PC":
          await handleMSALLogoutDialogue(instance);
          break;
      }

      instance.setActiveAccount(null);
      refreshMsalInstance();
    } catch (e) {
      console.log("Error Logging out: ", e);
    }
  };

  return (
    <>
      {!isAuthenticated && (
        <Button
          primary
          styles={{
            root: {
              width: "100%",
              marginBottom: "20px",
              ...styles,
            },
          }}
          onClick={() => onLoginClicked(instance)}
        >
          Sign in to Microsoft Account
        </Button>
      )}

      {isAuthenticated && (
        <Button
          primary
          styles={{
            root: {
              width: "100%",
              marginBottom: "20px",
              ...styles,
            },
          }}
          onClick={() => onLogoutClicked(instance)}
        >
          Sign out of Microsoft Account
        </Button>
      )}
    </>
  );
};

export default MicrosoftLoginButton;
