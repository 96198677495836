import { find } from "lodash";

export const isOffice = !!(Office.context && Office.context.mailbox);

export const loadMessageBody = async (item, type) => {
  return new Promise((resolve) => {
    item.body.getAsync(Office.CoercionType[type], function (asyncResult) {
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
        resolve("");
        console.error(`Error, Loading ${type}`);
      } else {
        resolve(asyncResult.value.trim());
      }
    });
  });
};

export function getConfig(key, defaultValue = false) {
  console.log("get Config for ", key);
  try {
    return window.localStorage.getItem(key) || defaultValue;
  } catch (e) {
    console.log(e);
  }

  try {
    /*
    if (Office && Office.context.roamingSettings) {
      let found = Office.context.roamingSettings.get(key);
      return found || defaultValue;
    }*/

    return window.localStorage.getItem(key) || defaultValue;
  } catch (e) {
    console.log("Error reading config: ", e);
  }
}

export async function setConfig(key, value) {
  try {
    return window.localStorage.setItem(key, value);
  } catch (e) {
    console.log(e);
  }

  if (Office && Office.context.roamingSettings) {
    try {
      Office.context.roamingSettings.set(key, value);
      Office.context.roamingSettings.saveAsync((r) => {
        if (r.status !== Office.AsyncResultStatus.Succeeded) {
          console.error(`Action failed with message ${r.error.message}`);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  return window.localStorage.setItem(key, value);
}

/* global Office */
export async function deleteItem(msgId) {
  return Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, function (result) {
    //var ewsId = Office.context.mailbox.item.itemId;
    const token = result.value;
    const restId = Office.context.mailbox.convertToRestId(msgId, Office.MailboxEnums.RestVersion.v2_0);
    const resource = Office.context.mailbox.restUrl + "/v2.0/me/messages/" + restId;

    let xhr = new XMLHttpRequest();
    xhr.open("DELETE", resource, true);
    xhr.setRequestHeader("Authorization", "Bearer " + token);
    //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");

    xhr.onload = function (e) {
      console.log("deleting item ", e, this.response);
    };

    return xhr.send();
  });
}

export function itemAddInfo(item, info = "Undefined info", key = "x", type = "informationalMessage") {
  if (Office.context && Office.context.mailbox) {
    try {
      return Office.context.mailbox.item.notificationMessages.addAsync(item.id ? "Updated " : "Filed ", {
        icon: "Icon.16x16",
        key: key,
        message: info,
        persistent: true,
        type: type,
      });
    } catch (e) {
      console.log(e);
    }
  }
}

export async function itemAddCategory(category) {
  if (Office.context && Office.context.mailbox) {
    await addCategory(category);

    return Office.context.mailbox.item.categories.addAsync([category], (asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        console.log(`Successfully assigned category '${category}' to item.`);
      } else {
        console.log("categories.addAsync call failed with error: " + asyncResult.error.message);
      }
    });
  }
}

export function addCategory(label = "New Category", colorPreset = "Preset0") {
  return new Promise((resolve, reject) => {
    if (Office.context && Office.context.mailbox) {
      Office.context.mailbox.masterCategories.getAsync(function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          console.log("Action failed with error: " + asyncResult.error.message);
        } else {
          let masterCategories = asyncResult.value;
          if (!find(masterCategories, { displayName: label })) {
            const masterCategoriesToAdd = [
              {
                displayName: label,
                color: Office.MailboxEnums.CategoryColor[colorPreset],
              },
            ];

            Office.context.mailbox.masterCategories.addAsync(masterCategoriesToAdd, function (asyncResult) {
              if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
                console.log("masterCategories.addAsync call failed with error: " + asyncResult.error.message);
              }
            });
          }
        }
      });

      resolve(true);
    } else {
      reject();
    }
  });
}

export function lengthInUtf8Bytes(str) {
  const m = encodeURIComponent(str).match(/%[89ABab]/g);
  return str.length + (m ? m.length : 0);
}

export function openPopup(route = false, onMessage = () => {}, options = {}) {
  let _options = { width: 30, height: 70, promptBeforeOpen: false, displayInIframe: true, ...options };

  if (route.indexOf("https") < 0) {
    route = window.location.origin + "/" + route;
  }

  return Office.context.ui.displayDialogAsync(route || window.location.href, _options, (asyncResult) => {
    let dialog = asyncResult.value;
    dialog.addEventHandler(Office.EventType.DialogMessageReceived, (response) => {
      onMessage(response, dialog);
    });
  });
}

export const getMessageColumns = (mailData, defaults = false) => {
  // iterate through fields and add data to matching columns
  let fieldsMap;
  try {
    fieldsMap = [
      {
        key: "subject",
        label: "Subject",
        columns: defaults["subject"] || "Subject",
        data: mailData ? mailData.subject : "",
        info: "Display emails subject line",
      },
      {
        key: "subject_orig",
        label: "Original Subject",
        columns: defaults["subject_orig"] || "Original Subject",
        data: mailData ? mailData.sanitizedSubject || mailData.subject : "",
        info: "Display emails orginial subject line",
      },
      {
        key: "from_name",
        label: "From Name",
        columns: defaults["from_name"] || "From, SenderName, Sender",
        data: mailData ? mailData.sender.emailAddress.name : "",
        info: "Display senders name",
      },
      {
        key: "from_email",
        label: "From Email",
        columns: defaults["from_email"] || "From Email, Sender mail, Email",
        data: mailData ? mailData.sender.emailAddress.address : "",
        info: "Display from email address",
      },
      {
        key: "recipients",
        label: "Recipients",
        columns: defaults["recipients"] || "To,Recipients,Recipients",
        data: mailData
          ? mailData.toRecipients.map((e) => e.emailAddress.name + " (" + e.emailAddress.address + ")").join(",\n")
          : "",
        info: "Display list of additional recipients",
      },
      {
        key: "cc_recipients",
        label: "CC Recipients",
        columns: defaults["cc_recipients"] || "CC, CC Recipients",
        data: mailData
          ? mailData.toRecipients.map((e) => e.emailAddress.name + " (" + e.emailAddress.address + ")").join(",\n")
          : "",
        info: "Display list of CC recipients",
      },
      {
        key: "date",
        label: "Date",
        columns: defaults["date"] || "Date",
        data: !mailData ? "" : mailData.receivedDateTime,
        info: "Display receive date",
      },
      {
        key: "preview",
        label: "Preview",
        columns: defaults["preview"] || "Preview",
        data: !mailData ? "" : mailData.bodyPreview,
        info: "Display a preview of the emails content. Configure column as multi-line text",
      },
      {
        key: "message_id",
        label: "Message ID",
        columns: defaults["message_id"] || "ID",
        data: !mailData ? "" : mailData.id,
        info: "Display message ID",
      },
      {
        key: "importance",
        label: "Importance",
        columns: defaults["importance"] || "Importance",
        data: !mailData ? "" : mailData.importance || "",
        info: "Display Outlooks message importance as text",
      },
      {
        key: "conversation",
        label: "Conversation ID",
        columns: defaults["conversation"] || "Conversation",
        data: !mailData ? "" : mailData.conversationId || "",
        info: "Display conversation ID",
      },
      {
        key: "conversation_index",
        label: "Conversation Index",
        columns: defaults["conversation_index"] || "ConversationIndex",
        data: !mailData ? "" : mailData.conversationIndex || "",
        info: "Display conversation index",
      },
      {
        key: "conversation_topic",
        label: "Conversation Topic",
        columns: defaults["conversation_topic"] || "ConversationTopic",
        data: !mailData ? "" : mailData.conversationTopic || "",
        info: "Display list of additional recipients",
      },
      {
        key: "categories",
        label: "Categories",
        columns: defaults["categories"] || "Categories",
        data: !mailData ? "" : mailData.categories.join(", "),
        info: "Display emails categories BEFORE filing",
      },
      {
        key: "attachments",
        label: "Has attachments",
        columns: defaults["attachments"] || "Has Attachments, Attachments",
        data: !mailData ? "" : mailData.hasAttachments,
        info: "Display 'yes' or 'no' if email had attachments. Configure SharePoint column as Boolean field or as Text",
      },
    ];

    return fieldsMap;
  } catch (e) {
    console.log("error building columns map:", e);
  }
};
