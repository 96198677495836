import React from "react";
import { isOffice, openPopup as openPopupOutlook } from "./Office.helper";
import { useHistory } from "react-router-dom";

// handles the decision to open a redirect in a popup
export const openOfficePopup = (route = "/", onMessage = () => {}, options = {}) => {
  if (Office && Office.context.diagnostics.platform === "OfficeOnline" && Office.context.host) {
    return openPopupOutlook(
      route,
      (m, dialog) => {
        onMessage(m, dialog);
      },
      options
    );
  }

  return window.open(route, "_blank", options);
};

/* global Office */
export const openPopup = (route = "/", onMessage = () => {}, forceNewWindow = false, options = {}) => {
  if (Office && Office.context.host === "Outlook") {
    openPopupOutlook(route, onMessage, options);
    return;
  }

  window.open(route, "_blank", options);
};

export const openLarge = (route = "/", onMessage = () => {}, forceNewWindow = false, options = {}) => {
  if (isOffice) {
    openPopupOutlook(route, onMessage, options);
  }
  //  window.open(route, "_blank", options);
};

export const useLargeLink = (props) => {
  const history = useHistory();

  const open = (isOffice, route, inMessage, options) => {
    if (isOffice) {
      openPopupOutlook(route, onMessage, options);
      return;
    }
    history.push(route);
  };

  return { open };
};
