import React from "react";
import { Progress } from "../UI";

// Fetching users plugin registry / SessionContext??
const extensions = [
  /*
  {
    name: "InContext",
    id: "incontext",
    enabled: false,
    manifest: import("../plugins/incontext/index"),
  },
  
  */
  {
    name: "SharePoint",
    id: "sharepoint",
    enabled: true,
    manifest: import("../plugins/sharepoint/index"),
  },
  {
    name: "Outlook categories",
    id: "outlookCategories",
    enabled: true,
    manifest: import("../plugins/outlookCategories/index"),
  },
  {
    name: "Outlook shortcuts",
    id: "outlookShortcuts",
    enabled: true,
    manifest: import("../plugins/outlookShortcuts/index"),
  },
];

const loadPlugins = (extensions) => {
  let plugins = [];
  let queue = [];

  extensions.map(async (extension) => {
    if (extension.enabled) {
      queue.push(
        // note: to register all plugins in parallel run it as promise chain instead of sequentually with "asycn/wait"
        new Promise((resolveManifest) => {
          extension.manifest.then((manifest) => {
            // register plugin components
            manifest.manifest.features.map((feature) => {
              if (feature.enabled && feature.userEnabled) {
                plugins.push(feature);
              }
            });
            resolveManifest();
          });
        })
      );
    }
  });

  return Promise.all(queue).then((r) => {
    console.log("Plugins registered: ", plugins);
    return { plugins };
  });
};

export const PluginsContext = React.createContext({});
export const PluginsProvider = ({ children }) => {
  const [plugins, setPlugins] = React.useState(false);

  React.useEffect(() => {
    loadPlugins(extensions).then((r) => {
      setPlugins(r.plugins);
    });
  }, []);

  const getPluginRoutes = () => plugins.filter((p) => p.route || false);

  const getPluginByRoute = (route) => {
    if (route) {
      let plugin = plugins.find((p) => p.route && p.route === route);
      console.log(plugin);
      return plugin || false;
    }
    return false;
  };

  const getPlugins = (scope) => {
    return plugins.filter((p) => p.type === "component" && p.scope === scope);
  };

  /**
   * execute worker functions for a given scope
   *
   * @param scope
   * @param data
   * @param pluginInterface = the interface to scope and context data
   *
   * @returns Promise when all worker are finished
   * @constructor
   */
  const PluginWorker = async (scope, data, pluginInterface = false) => {
    let promises = [];

    plugins.map(async (p) => {
      if (p.type === "worker" && p.scope === scope) {
        promises.push(
          new Promise(async (rs, rj) => {
            try {
              const worker = await p.worker;
              console.log("Starting worker ", p.name);
              worker(data, pluginInterface).then((r) => {
                console.log("Worker finished: ", p.name, r);
                rs(rs);
              });
            } catch (e) {
              console.log("Error in Worker ", p.name, e);
              rj(e);
            }
          })
        );
      }
    });

    // resolve after promise chain
    return Promise.all(promises)
      .then((r) => {
        console.log("Resolved all worker for ", scope, r);
      })
      .catch((e) => {
        console.log("Error executing worker: ", e);
      });
  };

  /**
   * execute processor functions for a given scope
   *
   * @param scope
   * @param data
   * @param pluginInterface = the interface to scope and context data
   *
   * @returns Promise when all worker are finished
   * @constructor
   */
  /*
  const PluginProcessor = async (scope, data, pluginInterface) => {
    if (!processors || !processors.length) {
      return new Promise.resolve([]);
    }

    let promises = [];
    try {
      for (let p in processors) {
        if (processors[p]?.key && processors[p].key === scope) {
          const processor = await processors[p].worker;
          promises.push(processor(data, pluginInterface));
        }
      }
    } catch (e) {
      console.log("Error in processors");
      return Promise.reject(e);
    }

    // resolve after promise chain
    return Promise.all(promises).then((r) => {
      console.log("Resolved all processors for ", scope, r);
    });
  };

   */

  // render plugins slot function
  const PluginRender = (props, children) => {
    return React.useMemo(() => {
      const plugins = getPlugins(props.scope);

      if (!plugins) {
        return false;
      }

      let components = [];

      //  a plugin has to return a list of components
      plugins.map((plugin) => {
        console.log(plugin);
        components.push(
          <plugin.component key={plugin.id} {...props}>
            {children}
          </plugin.component>
        );
      });

      return components;
    }, [...(props.memoizeOn || [])]);
  };

  if (plugins) {
    return (
      <PluginsContext.Provider
        value={{
          plugins,
          getPlugins,
          getPluginRoutes,
          getPluginByRoute,
          PluginRender,
          PluginWorker,
        }}
      >
        {children}
      </PluginsContext.Provider>
    );
  }

  return <Progress message={"Loading plugins"} />;
};
