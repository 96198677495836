import React from "react";
import { FontIcon } from "@fluentui/react";
import { Link, withRouter } from "react-router-dom";
import { Button, Container, Header2 } from "../../UI";
import { openOfficePopup } from "../../helpers/UI.helper";
import { css } from "@emotion/react";
import { filter, toArray } from "lodash";
import { MenuItems as menuItems } from "../../NavigationItems";
import OfficeContext from "../../contexts/OfficeContext";
import { PluginsContext } from "../../contexts/PluginsContext";

const Settings = () => {
  const { getPlugins } = React.useContext(PluginsContext);
  const { isOffice } = React.useContext(OfficeContext);
  let settings = [...toArray(filter(menuItems, "settings")), ...getPlugins("settings")];

  const onSignoutClicked = async () => {
    // trying without popup - some platform might require popup flow though
    try {
      await auth.signOut();
    } catch (e) {
      // needs to be a conditional redirect or popup
      openOfficePopup(
        "/logout.html",
        (message, dialog) => {
          console.log("Message ", message, dialog);

          if (JSON.parse(message.message).messageType === "logoutSuccess") {
            dialog.close();
          }
        },
        {
          width: 50,
          height: 10,
          promptBeforeOpen: false,
          displayInIframe: true,
        }
      );
    }
  };

  return (
    <Container>
      <Header2>Settings</Header2>
      <ul
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;

          a {
            padding: 10px 0;
            text-decoration: none;
            display: flex;
            gap: 10px;
            align-items: center;
            color: #005a9e;
            font-size: 1.1em;
          }
        `}
      >
        {settings.map((item, key) => {
          return (
            <li key={key}>
              <Link to={"/" + item.route || item.key}>
                {item.icon && typeof item.icon() === "string" && <FontIcon iconName={item.icon()} />}
                {item.icon && typeof item.icon() === "object" && item.icon()}
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>

      {isOffice && (
        <div>
          <h3>Sign out of InContext</h3>
          <p>
            <Button label={"Sign out"} onClick={onSignoutClicked} />
          </p>
        </div>
      )}
    </Container>
  );
};

export default withRouter(Settings);
