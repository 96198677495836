import React from "react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";

export const TextButton = (props) => {
  const tooltipId = useId("tooltip");

  const {
    label = props.children,
    onClick = () => {},
    icon = false,
    IconComponent = false,
    iconPosition = "left",
    tooltip = false,
    tooltipDelay = 2,
    disabled = false,
    styles = false,
    className = false,
    ariaLabel = props.ariaLabel || props.label || props.children,
  } = props;

  const _styles = {
    ...{
      border: "none",
      padding: "4px 5px",
      minWidth: "10px",
      textAlign: "left",
      height: "fit-content",
      background: "none",
      "&:hover": {
        backgroundColor: "#eaeaea",
      },
    },
    ...styles,
  };

  const Icon = () => (IconComponent ? IconComponent : <FontIcon iconName={icon} />);
  const IconWithHover = tooltip
    ? () => (
        <TooltipHost content={tooltip} delay={tooltipDelay} id={tooltipId}>
          <Icon />
        </TooltipHost>
      )
    : () => <Icon />;

  return (
    <DefaultButton
      ariaLabel={ariaLabel}
      type={"button"}
      disabled={disabled}
      className={className}
      styles={{ root: { ..._styles, ...styles } }}
      onClick={onClick}
    >
      {icon && (iconPosition === "left" || !iconPosition) && <IconWithHover />}
      {label && <span>{label}</span>}
      {icon && iconPosition === "right" && <IconWithHover />}
    </DefaultButton>
  );
};
