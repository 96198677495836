import React from "react";
import styled from "@emotion/styled";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";

const defaultStyles = {
  borderRadius: "4px",
  transition: "background-color 0.156s ease",
};
const ButtonIcon = styled(FontIcon)`
  margin-right: 5px;
`;

export const Button = (props) => {
  const tooltipId = useId("tooltip");

  const {
    label = "",
    text = label,
    onClick = () => {
      console.log("onButtonClicked");
    },
    icon = false,
    IconComponent = false,
    iconPosition = "left",
    tooltip = false,
    tooltipDelay = 2,
    disabled = false,
    styles = {},
    primary = false,
    textButton = false,
  } = props;

  const Icon = () => (IconComponent ? IconComponent : <ButtonIcon iconName={icon} />);
  const IconWithHover = tooltip
    ? () => (
        <TooltipHost content={tooltip} delay={tooltipDelay} id={tooltipId}>
          <Icon />
        </TooltipHost>
      )
    : () => <Icon />;

  return (
    <DefaultButton
      ariaLabel={label}
      type={"button"}
      disabled={disabled}
      styles={{ ...defaultStyles, ...styles }}
      primary={primary}
      className={textButton ? "textButtonClass" : ""}
      onClick={onClick}
    >
      {icon && iconPosition === "left" && <IconWithHover />}
      {props.children || text}
      {icon && iconPosition === "right" && <IconWithHover />}
    </DefaultButton>
  );
};
