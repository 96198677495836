const item = {
  id: "dsf",
  itemId: "AQQkAGM4YzkwMAItNzg1Ni04OTg4AC0wMAItMDAKABAAlovhH5WioPx4sgWQ68ox",
  attachments: [],
  bcc: [],
  bodyText: "BodyText",
  cc: [],
  to: [
    {
      displayName: "bosselmann@gmail.com",
      emailAddress: "bosselmann@gmail.com",
      recipientType: "externalUser"
    }
  ],
  conversationId: "AQQkAGM4YzkwMAItNzg1Ni04OTg4AC0wMAItMDAKABAAlovhH5WioPx4sgWQ68o",
  internetMessageId: "AQQkAGM4YzkwMAItNzg1Ni04OTg4AC0wMAItMDAKABAAlovhH5WioPx4sgWQ68o",
  from: {
    appointmentResponse: "none",
    displayName: "Florian Bosselmann",
    emailAddress: "florian@ultradigitalxx.de",
    recipientType: "externalUser"
  },
  location: "",
  itemClass: "IPM.Note",
  itemType: "message",
  sender: {
    appointmentResponse: "none",
    displayName: "Florian Bosselmann",
    emailAddress: "florian@ultradigitalxx.de",
    recipientType: "externalUser"
  },
  subject: "Fwd: Wie wäre es heute Abend mit Netflix?",
  normalizedSubject: "Wie wäre es heute Abend mit Netflix?"
};

export default item;
