import React from "react";
import { withRouter } from "react-router-dom";
import OfficeContext from "../../contexts/OfficeContext";

const HomeOutlook = React.lazy(() => import("./HomeOutlook"));
const HomeWeb = React.lazy(() => import("./HomeWeb"));

const Home = () => {
  const { isOffice } = React.useContext(OfficeContext);
  return isOffice ? <HomeOutlook /> : <HomeWeb />;
};

export default withRouter(Home);
