// The configuration below is not sensitive data. You can serenely add your config here
export const firebaseConfig = {
  apiKey: "AIzaSyBB_m-0O6xUtir6V1N3_XzNUaY5hQiNof8",
  authDomain: "lawrence-507c1.firebaseapp.com",
  databaseURL: "https://lawrence-507c1.firebaseio.com",
  projectId: "lawrence-507c1",
  storageBucket: "lawrence-507c1.appspot.com",
  messagingSenderId: "358839843643",
  appId: "1:358839843643:web:674ceea8a2532655ced51e",
  measurementId: "G-K4DTBV8LTZ",
  enablePersistence: true,
  persistence: true
};
