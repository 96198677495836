import React from "react";
import { filter, toArray } from "lodash";
import { Link, withRouter } from "react-router-dom";
import styled from "@emotion/styled";
import OfficeContext from "../../contexts/OfficeContext";
import { SessionContext } from "../../contexts/SessionContext";
import { MenuItems } from "../../NavigationItems";
import { FontIcon, ContextualMenu } from "@fluentui/react";
import { useConst } from "@fluentui/react-hooks";

const menuItems = toArray(filter(MenuItems, "header"));
const TopNavigationContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  padding: 10px 21px;

  ul {
    list-style: none;
    align-items: flex-start;
    flex-direction: row;
    height: 30px;
    font-size: 1.1em;

    li {
      a {
        text-decoration: none;
        animation: ease-in;
        transition-duration: 200ms;
        color: #94979e;

        &:hover {
          color: #0751b8;
        }
      }
    }
  }
`;

export const TopNavigation = ({ history }) => {
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const { isOffice } = React.useContext(OfficeContext);
  const { user, logout } = React.useContext(SessionContext);
  const linkRef = React.useRef(null);

  const onShowContextualMenu = React.useCallback((ev) => {
    ev.preventDefault(); // don't navigate
    setShowContextualMenu(true);
  }, []);
  const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);

  const menuProps = useConst(() => ({
    shouldFocusOnMount: true,
    shouldFocusOnContainer: true,
    items: [
      { key: "profile", text: "View profile", onClick: () => history.push("/profile") },
      {
        key: "logout",
        text: "logout",
        onClick: () => {
          logout();
        },
      },
    ],
  }));

  return (
    <TopNavigationContainer>
      <ul>
        {menuItems.map((comp) => {
          return (
            <li key={comp.key} className={`${comp.key === "flobo rocks" ? "active" : ""}`}>
              {comp.url && (
                <a
                  href={""}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(comp.url);
                  }}
                >
                  <FontIcon iconName={comp.icon()} />
                  <span>{comp.name}</span>
                </a>
              )}

              {!comp.url && (
                <Link to={`/${comp.key}`}>
                  <FontIcon iconName={comp.icon()} />
                  <span>{comp.name}</span>
                </Link>
              )}
            </li>
          );
        })}

        <li>
          {!isOffice && (
            <div>
              <a ref={linkRef} onClick={onShowContextualMenu} href="#">
                {user.data.displayName || "User"}
              </a>
              <ContextualMenu
                items={menuProps.items}
                hidden={!showContextualMenu}
                target={linkRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
              />
            </div>
          )}
        </li>
      </ul>
    </TopNavigationContainer>
  );
};

export default withRouter(TopNavigation);
