import React from "react";
import OrganisationDB from "../firebase/organisation-db";
import GenericDB from "../firebase/generic-db";
import { FirebaseContext } from "../firebase/FirebaseProvider";
import { doc, setDoc, updateDoc } from "firebase/firestore/lite";
import SessionContext from "../contexts/SessionContext";
import UsersDB from "../firebase/users-db";

// expects a user to already exist
export async function createOrg(db, user) {
  const OrgsDB = new GenericDB(db, "organisations");
  const usersDb = await new UsersDB(db);

  console.log("Create new org");
  let _orgDoc = await OrgsDB.create({
    data: {
      name: user.data.displayName || user.data.email.split("@")[1] + "'s team",
    },
  });

  console.info("Add new organisation to auth user");
  setDoc(doc(db, `organisations/${_orgDoc.id}/users`, user.id), { id: user.id, ref: doc(db, `users`, user.id) })
    .then((r) => {
      console.log(r);
    })
    .catch((e) => {
      console.log("Error adding organisation to user: ", e);
    });

  await usersDb.addOrgToUser(user, _orgDoc);

  return _orgDoc;
}

export const createUser = async (db, fireUser) => {
  const providerData = fireUser.providerData[0];
  const { displayName, photoURL, email } = providerData;
  const usersDb = await new UsersDB(db);

  const userData = {
    data: {
      displayName: displayName || email,
      name: displayName || email,
      photoURL: photoURL,
      email: email,
    },
  };

  try {
    return await usersDb.create(userData, fireUser.uid);
  } catch (e) {
    console.error("Error creating user ", e);
  }
};

export const saveOrgSettings = async (orgRef, path, data) => {
  if (!path) {
    throw "path required to save settings";
  }

  let update = {};
  update["settings." + path] = data;
  await updateDoc(orgRef, update);
};

export const getOrgSettings = async (db, id) => {
  if (id) {
    try {
      const docRef = await db.collection("organisations").doc(id);
      const doc = await docRef.get();

      if (!doc.exists) {
        console.error("Org not found");
        return false;
      } else {
        console.log("Document data:", doc.data());
        return doc.data().settings;
      }
    } catch (e) {
      console.log("Error getting org settings ", e);
    }
  }
};

const getUsers = (db, id) => {
  const orgsDB = OrganisationDB(db, id);
  const orgUsersRef = orgsDB.collection("users");

  return orgUsersRef.getAll();
};

export const useOrganisation = (OrgId) => {
  const [id, setId] = React.useState(false);
  const { db } = React.useContext(FirebaseContext);
  const { orgRef } = React.useContext(SessionContext);

  React.useEffect(() => {
    if (OrgId) {
      setId(OrgId);
      //loadOrganisation(OrgId);
    }
  }, [OrgId]);

  const loadOrganisation = async (id) => {
    const orgsRef = new GenericDB(db, "organisations");
    const orgDoc = (await orgsRef.read(id)) || false;
    let orgData = orgDoc.data();

    console.log("DELETE: ORG HELPER LOADED");
    console.log(orgData);
  };

  return {
    loadOrganisation: loadOrganisation,
    orgId: id,
    saveOrgSettings: (path, data) => saveOrgSettings(orgRef, path, data),
    getOrgSettings: (path = false) => getOrgSettings(db, OrgId, path),
    users: () => getUsers(db),
    createUser: (userdata) => createUser(db, userdata),
    createOrg: (userdata) => createOrg(db, userdata),
  };
};
