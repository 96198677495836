import React from "react";
import { TextButton, Shimmer } from "./index";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Icon, Label, ComboBox, Stack, TooltipHost, FontIcon } from "@fluentui/react";
import { Link } from "react-router-dom";

const ItemLabel = styled(Label)`
  font-weight: bold;
`;

const NewBadge = styled.div`
  font-size: 0.8rem;
  padding: 2px 4px;
  background-color: #44973b;
  color: #fff;
  border-radius: 11%;
  font-weight: bold;
  margin: 0 5px 0;
  height: 21px;

  &:after {
    content: "new";
  }
`;

const stackTokens = {
  childrenGap: 5,
  display: "flex",
  marginBottom: 20,
};

const labelClass = {
  flex: "20% 0 0",
};

const LinkStyled = styled(Link)`
  font-size: 1.5rem;
  color: #7f919e;

  &:hover {
    color: #0492f9;
  }
`;

// info Icon and ToolTip
const infoIconTooltipCalloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const infoIconTooltipHostStyles = { root: { display: "inline-block" } };

const InfoIcon = styled(FontIcon)`
  font-size: 1.5rem;
  color: #0489f2;
  cursor: help;
`;

const DropdownStack = styled(Stack)`
  flex: 1;
  flex-direction: row;
  display: flex;
`;

const stackStyles = {
  root: {
    width: "100%",
    marginBottom: "10px",
  },

  selectors: {
    "& > *": {
      display: "flex",
    },
    "& .ms-Button--action": {
      minWidth: "100%",
    },
    // '& .ms-ComboBox': { maxWidth: '300px' }
  },
};

export const DroplistRow = (props) => {
  const [showDroplist, setShowDroplist] = React.useState(false);
  const [value, setValue] = React.useState(props.value);
  const [options, setOptions] = React.useState(props.options);

  if (!props.value) {
    return <Shimmer segments={[10, 12, 70]} height={15} />;
  }

  const {
    placeholder = "",
    label = "label",
    allowFreeform = true,
    onSelect: onSelectCallback = () => console.log("setValue not defined "),
    context = false,
    required = false,
    infoIconProps = false,
    allowListAll = true,
    filterSameOn = () => false,
  } = props;

  const select = (event, option, key, value) => {
    let newValue = option || {
      data: { name: value },
      id: null,
      text: value,
      key: "new",
    };

    setValue(newValue);

    if (onSelectCallback) {
      onSelectCallback(newValue);
    }

    setShowDroplist(false);
  };

  // Map all and related entries
  const mapped = React.useMemo(() => {
    if (!value) {
      return [];
    }

    let map = [];
    let mapped = options.length
      ? options.map((e) => ({
          ...e,
          key: e.id,
          text: e.data.name,
          sameClient: filterSameOn ? filterSameOn(e, value) : false,
        }))
      : [];

    const sameClients = mapped.filter((m) => m.sameClient);
    const notSame = mapped.filter((m) => !m.sameClient);

    if (!value.id) {
      map.push({
        ...value,
        text: value.data.name,
        key: "new",
      });
    }

    if (sameClients.length) {
      map.push({ key: "related", text: "Same client", itemType: 2 });
      map.push(...sameClients);
    }

    if (allowListAll && notSame.length) {
      map.push({ key: "all", text: "All", itemType: 2 });
      map.push(...notSame);
    }

    return map;
  }, [value, options]);

  return (
    <DropdownStack horizontal verticalAlign="start" tokens={stackTokens} styles={stackStyles}>
      <Stack.Item className={labelClass}>
        <ItemLabel>{label}</ItemLabel>
      </Stack.Item>

      {!showDroplist && (
        <>
          <TextButton
            onClick={() => setShowDroplist(true)}
            label={value.data.name}
            placeholder={placeholder}
            styles={{
              root: {
                width: "100%",
              },
              flexContainer: {
                justifyContent: "left",
              },
            }}
          />

          {value && !value.id && <NewBadge />}

          {infoIconProps && (
            <TooltipHost
              content={infoIconProps.text}
              calloutProps={infoIconTooltipCalloutProps}
              styles={infoIconTooltipHostStyles}
            >
              <InfoIcon iconName={infoIconProps.iconName} />
            </TooltipHost>
          )}

          {value && value.id && (
            <LinkStyled to={"/" + context + "/" + value.id}>
              <Icon iconName={"PageRight"} />
            </LinkStyled>
          )}
        </>
      )}

      {showDroplist && (
        <ComboBox
          options={mapped}
          onChange={select}
          onItemClick={select}
          placeHolder={placeholder}
          allowFreeform={allowFreeform}
          defaultSelectedKey={value.id || "new"}
          onBlur={() => setShowDroplist(false)}
          autoComplete="on"
          scrollSelectedToTop={true}
          useComboBoxAsMenuWidth={true}
          isRequired={required}
          focus={true}
          iconButtonProps={{ iconName: "New" }}
          onRenderOption={(option) => {
            return (
              <div
                css={css`
                  display: flex;
                  flex: 1;
                  flex-direction: row;
                `}
              >
                <span
                  css={css`
                    flex: 1;
                  `}
                >
                  {option.text}
                </span>
                {!option.itemType && !option.id && <NewBadge />}
              </div>
            );
          }}
        />
      )}
    </DropdownStack>
  );
};
