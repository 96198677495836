import React from "react";
import { Section } from "../../UI/styled";
import styled from "@emotion/styled";
import { useMediaQuery } from "beautiful-react-hooks";
import OfficeContext from "../../contexts/OfficeContext";
import { css } from "@emotion/react";
import { InContextHeader } from "../../UI/InContextHeader";

const LoginWebContent = styled.div`
  min-width: 150px;
  display: flex;
  background-color: #051f99;
  background: linear-gradient(0deg, rgb(34 3 78 / 12%) 0%, rgb(9 13 121 / 13%) 29%, rgb(4 33 159 / 6%) 100%);
  color: #fff;
  font-size: 2vw;
  box-shadow: inset 11px 0 6px 0 #00000014;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 5%;
  padding-top: 10%;

  h3 {
    font-weight: normal;
    font-size: 3vw;
  }
`;

const LoginView = (props) => {
  const { isOffice } = React.useContext(OfficeContext);
  const isSmall = isOffice || useMediaQuery("(max-width: 450px)");

  return (
    <>
      <Section
        css={css`
          flex: 4;
        `}
      >
        <InContextHeader />

        {props.children}
      </Section>

      {!isSmall && (
        <LoginWebContent vertical>
          <h3>{""}</h3>
          <p>{""}</p>
          <h4>{""}</h4>
        </LoginWebContent>
      )}
    </>
  );
};

export default LoginView;
