import React from "react";
import styled from "@emotion/styled";
import { TextField as TextFieldOffice } from "@fluentui/react";

const TextFieldStyled = styled(TextFieldOffice)`
  border: none;
  padding: 5px 0px;
  min-width: 10px;
  text-align: left;
  height: fit-content;
  min-height: 32px;
  background: none;
  width: 100%;
  flex: 1;
`;

export const TextField = props => {
  return <TextFieldStyled {...props} />;
};
