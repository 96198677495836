import React from "react";
import OfficeContext from "./contexts/OfficeContext";
import SessionContext from "./contexts/SessionContext";
import LogRocket from "logrocket";

import { Route, Switch } from "react-router-dom";
import { MenuItems } from "./NavigationItems";
import { useOrganisation } from "./helpers/Organisation.hook";
import { Progress } from "./UI";
import { getDoc } from "firebase/firestore/lite";

import UsersDB from "./firebase/users-db";
import { useMediaQuery } from "beautiful-react-hooks";
import { addCategory } from "./helpers/Office.helper";
import Actionsbar, { ActionsbarItemsContext } from "./components/actionsbar";

import Navbar from "./components/topNavigationOffice";
import TopNavigation from "./components/topNavigation";
import { PluginsContext } from "./contexts/PluginsContext";
import { Section } from "./UI/styled";
import { FirebaseContext } from "./firebase/FirebaseProvider";
import { initializeIcons } from "@fluentui/react/lib/Icons";

const SideNavigation = React.lazy(() => import("./components/sideNavigation"));
const PinNotice = React.lazy(() => import("./components/pinNotice"));

export const CurrentItemContext = React.createContext(() => {});
export const CurrentComponentContext = React.createContext(() => {});

initializeIcons();

// todo this should go into a central config as it is used in the labels component too
const defaultLabels = {
  email: { title: "Email filed", color: "red" },
  attachments: { title: "Attachments filed", color: "red" },
};

// listen to selected email here to make it app wide available
const loadItemData = (item) => {
  return {
    id: null,
    data: item,
    auditInfo: [],
  };
};

const App = () => {
  const [currentItem, setCurrentItem] = React.useState(null);
  const [currentComponent, setCurrentComponent] = React.useState(null);
  const [actionbarItems, setActionbarItems] = React.useState([]);
  const { isOffice, _Office } = React.useContext(OfficeContext);
  const isSmall = isOffice || useMediaQuery("(max-width: 450px)");
  const { user, setUser, organisation, setOrganisation, setOrgRef } = React.useContext(SessionContext);
  const { user: fireUser, db } = React.useContext(FirebaseContext);
  const { createUser, createOrg } = useOrganisation(db);
  const { getPluginByRoute } = React.useContext(PluginsContext);

  React.useEffect(() => {
    if (isOffice) {
      // setup office categories
      for (let label in defaultLabels) {
        try {
          addCategory(defaultLabels[label].title);
        } catch (e) {
          console.error("Error creating category: ", e);
        }
      }

      if (Office.context) {
        Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, () => {
          setCurrentItem(loadItemData(Office.context.mailbox.item));
        });

        // and load current item
        if (Office.context.mailbox.item) {
          setCurrentItem(loadItemData(Office.context.mailbox.item));
        }
      } else {
        // for development purposes
        setCurrentItem(loadItemData(require("./helpers/fakeItem").default));
      }
    }

    return () => {
      if (isOffice) {
        Office.context.mailbox.removeHandlerAsync(Office.EventType.ItemChanged);
      }
    };
  }, []);

  // when firebase spits out the auth data get going
  React.useEffect(() => {
    console.log("Firebase user has updated");
    if (fireUser) {
      loadAccount();
    }
  }, [fireUser]);

  // for logging set up user and pipe through to logRocket
  React.useEffect(() => {
    try {
      if (user) {
        LogRocket.identify(fireUser.uid, {
          firebaseUser: fireUser.name || fireUser.displayName || "- unknown",
          name: user.displayName || "- unknown",
          organisation: organisation.data?.name || "- no name",
          domain: organisation.data?.domain || "no domain",
          email: user.email || "unknown",
          provider: user.providerId,
        });
      }
    } catch (e) {
      console.log("Error setting up logging: ", e);
    }
  }, [user, organisation]);

  const loadAccount = async (setFireUser = false) => {
    let _usersDb = await new UsersDB(db);
    let _user = await _usersDb.read(setFireUser?.uid || fireUser.uid);
    let _organisation;

    // No user found - must be a new user
    if (!_user) {
      _user = await createUser(setFireUser || fireUser);
      _organisation = await createOrg(_user);
    }

    try {
      if (_user.organisations && Object.keys(_user.organisations).length > 0) {
        console.log("Loading users org");
        // expects organisation to be a reference
        await getDoc(_user.organisations[Object.keys(_user.organisations)[0]]).then((snapshot) => {
          return (_organisation = snapshot.data());
        });
      } else {
        // User has no active Organisations
        // intermediate solution: create a new org and assign
        console.log("Creating new org");
        try {
          _organisation = await createOrg(_user);
        } catch (e) {
          console.error("Error creating organisation ", e);
        }
      }

      if (_organisation && _user) {
        setOrganisation(_organisation);
        setUser(_user);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getCurrentComponentFromRoute = (props) => {
    try {
      const menuItem =
        MenuItems[props.match.params.component.toLowerCase()] ||
        getPluginByRoute(props.match.params.component.toLowerCase()) ||
        MenuItems.home;

      const Comp = menuItem.component;
      //setCurrentComponent(Comp);

      return Comp;
    } catch (e) {
      console.error(e);
    }
  };

  // to check for first login
  const DefaultComponent =
    fireUser && !fireUser.metadata?.lastSignInTime ? MenuItems.home.component : MenuItems.messages.component;

  if (!organisation) {
    return <Progress message={"Loading Application ..."} />;
  }

  return (
    <CurrentItemContext.Provider value={{ currentItem }}>
      <CurrentComponentContext.Provider value={{ currentComponent, setCurrentComponent }}>
        {/* is either in Office or not */}

        {isOffice && <PinNotice />}
        {!isSmall && !isOffice && <SideNavigation />}

        <Section className="appContainer">
          {isSmall && <Navbar />}
          {!isSmall && <TopNavigation />}
          <ActionsbarItemsContext.Provider value={{ actionbarItems, setActionbarItems }}>
            <Actionsbar />
            <div className="viewComponentContainer">
              <Switch basename={"/taskpane"}>
                <Route
                  path="/:component"
                  render={(props) => {
                    const Comp = getCurrentComponentFromRoute(props);
                    return <Comp {...props} />;
                  }}
                />
                <Route component={DefaultComponent} />
              </Switch>
            </div>
          </ActionsbarItemsContext.Provider>
        </Section>
        {!isSmall && isOffice && <Navigation />}
      </CurrentComponentContext.Provider>
    </CurrentItemContext.Provider>
  );
};

export default App;
