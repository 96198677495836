import GenericDB from "./generic-db";

export default class UsersDB extends GenericDB {
  constructor(db) {
    super(db, `users`);
  }

  addOrgToUser = async (user, org) => {
    let data = { ...user, organisations: { ...(user.organisations || {}) } };
    data.organisations[org.id] = org.ref;
    return await this.update(user, data);
  };
}
