export const config = {
  authority: "https://login.microsoftonline.com/organizations",
  clientId: "83a9ea8d-a327-4781-ab6e-b52ad8bafd1a",
  redirectUri: window.origin + "/fallbackauthdialog.html",
  redirectLogoutUri: window.origin + "/fallbacklogout.html",
  scopes: ["user.read", "calendars.read", "Sites.Manage.All", "Mail.Read", "Directory.Read.All", "Mail.ReadWrite"],
};

export const msalConfig = {
  auth: {
    clientId: config.clientId,
    authority: config.authority,
    redirectUri: window.origin + "/fallbackauthdialog.html",
  },
  cache: {
    cacheLocation: "localStorage", // needed to avoid "login required" error
    storeAuthStateInCookie: true, // recommended to avoid certain IE/Edge issues
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: config.scopes,
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
