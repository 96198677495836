import React from "react";
import UserAvatar from "react-user-avatar";
import styled from "@emotion/styled";

const colors = [
  "#ffb142", // emerald
  "#517cb0", // carrot
  "#84817a", //
  "#867373", // turquoise
  "#92c6dd", // peter river
];

const UserAvatarStyled = styled(UserAvatar)`
  .UserAvatar {
    display: inline-block;
    color: white;
  }

  .UserAvatar--inner {
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .UserAvatar--light {
    color: gray;
  }

  .UserAvatar--light .UserAvatar--inner {
    border: 1px solid lightgray;
  }
`;

export const Avatar = (props) => {
  const { user, onClick = () => {}, size } = props;
  return (
    <UserAvatarStyled
      name={user.displayName || "*"}
      src={user.photoURL || null}
      size={size || "100%"}
      colors={colors}
      onClick={onClick}
    />
  );
};
