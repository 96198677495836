import React from "react";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles = { root: { display: "inline-block" } };

export const InfoPill = props => {
  const { text = "empty" } = props;

  return (
    <div>
      <TooltipHost
        content={text}
        // This id is used on the tooltip itself, not the host
        // (so an element with this id only exists when the tooltip is shown)
        calloutProps={calloutProps}
        styles={hostStyles}
      >
        <i className={`ms-Icon ms-Icon--Info`} />
      </TooltipHost>
    </div>
  );
};
