import React from "react";
import { CurrentItemContext } from "../../App";
import { Container } from "../../UI";
import { VscAccount } from "react-icons/all";
import { css } from "@emotion/react";
import MicrosoftLoginButton from "../../UI/MicrosoftLogin";
import { useIsAuthenticated } from "@azure/msal-react";

const messageTypeComponents = {
  loginlink: {
    rule: (message) => message.itemClass === "IPM.Note" && message.normalizedSubject === "Sign in to InContext",
    component: React.lazy(() => import(`./loginlink`)),
  },
  message: {
    rule: (message) => message.itemClass === "IPM.Note",
    component: React.lazy(() => import(`./email`)),
  },
  appointment: {
    rule: (message) => message.itemClass === "IPM.Appointment" || message.itemClass === "IPM.Schedule.Meeting.Request",
    component: React.lazy(() => import(`./appointment`)),
  },

  unknown: {
    rule: () => true,
    component: React.lazy(() => import(`./unknown`)),
  },
};

/* global Office */
export default () => {
  const [MessageTypeContent, setMessageTypeComponent] = React.useState(false);
  const [item, setItem] = React.useState(false);
  const { currentItem } = React.useContext(CurrentItemContext);
  const isAuthenticated = useIsAuthenticated();

  React.useEffect(() => {
    if (!currentItem || (item && item.data.itemId === currentItem.data.itemId)) {
      return;
    }
    let found = false;
    console.log(currentItem);
    for (let [type, option] of Object.entries(messageTypeComponents)) {
      console.log(option);
      if (option.rule(currentItem.data)) {
        setMessageTypeComponent(option.component);
        setItem(currentItem);
        found = true;
        break;
      }
    }

    if (!found) {
      setMessageTypeComponent(messageTypeComponents.unknown.component);
    }
  }, [currentItem]);

  const Render = React.useMemo(() => {
    return MessageTypeContent ? <MessageTypeContent currentItem={currentItem} /> : "";
  }, [MessageTypeContent]);

  if (!isAuthenticated) {
    return (
      <Container>
        <div
          css={css`
            align-self: center;
            color: #666;
          `}
        >
          <VscAccount
            css={css`
              font-size: 3rem;
            `}
          />
        </div>

        <p>For InContext to work it needs access to your Microsoft Office account.</p>

        <MicrosoftLoginButton />
      </Container>
    );
  }

  return Render;
};
