import styled from "@emotion/styled";

const Bar = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Section = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 1rem;
  border: 1px solid #999;
  margin-bottom: 1rem;
  font-size: 0.8rem;
`;

const Button = styled.button`
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border-color: #3f4eae;
  border-radius: 3px;
  padding: 1rem;
  color: white;
  font-weight: 700;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 0.8rem;
`;

const Logo = styled.img`
  width: 50%;
  margin-bottom: 1rem;
`;

const Error = styled.div`
  background-color: #f4e5e5;
  padding: 5px;
  border: 1px solid #f62f35;
  border-radius: 2px;
  color: #f62f35;
`;

const Elevated = styled.div`
  flex-direction: row;
  display: flex;
  background-color: #fff;
  padding: 5px;
  margin-left: -7px;
  margin-right: -7px;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
  margin-bottom: 15px;
  white-space: pre-line;
`;

export { Form, Input, Button, Logo, Section, Error, Bar, Elevated };
