import React from "react";
import { getAuth } from "firebase/auth";
import { FirebaseContext } from "../firebase/FirebaseProvider";
import GenericDB from "../firebase/generic-db";

export const SessionContext = React.createContext({});
export const SessionProvider = ({ children }) => {
  const [user, setUser] = React.useState(false);
  const [organisation, setOrganisation] = React.useState(false);
  const [orgRef, setOrgRef] = React.useState(false);

  const { app, db } = React.useContext(FirebaseContext);
  const auth = getAuth(app);

  auth.onAuthStateChanged((user) => {
    setUser(user);

    if (!user) {
      setOrganisation(false);
    }
  });

  const updateOrganisation = (organisation) => {
    setOrganisation({ ...organisation });
  };

  const logout = () => {
    auth.signOut();
    setOrganisation({});
  };

  const loadOrganisation = async (id) => {
    console.log("loadOrganisation");
    let OrgDb = new GenericDB(db, "organisations");
    let org = await OrgDb.read(id);
    console.log(org);
  };

  return (
    <SessionContext.Provider
      value={{
        user,
        setUser,
        organisation,
        setOrganisation,
        setOrgRef,
        orgRef,
        loadOrganisation,
        updateOrganisation,
        logout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContext;
