import React from "react";
import { css } from "@emotion/react";

export const Heading = ({ align = "start", children, styles = false }) => (
  <h3
    css={css`
      ${styles};
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 8px;
      font-weight: normal;
    `}
  >
    {children}
  </h3>
);

export const Header1 = ({ align = "start", children, styles = false }) => (
  <h1
    css={css`
      ${styles};
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      gap: 8px;
      font-weight: lighter;
      font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
        "Helvetica Neue", sans-serif;
    `}
  >
    {children}
  </h1>
);

export const Header2 = ({ children, styles = false }) => (
  <h2
    css={css`
      ${styles};
      font-weight: lighter;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 8px;
      align-content: center;
      justify-content: flex-start;
      font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
        "Helvetica Neue", sans-serif;
    `}
  >
    {children}
  </h2>
);

export const Header3 = ({ align = "start", children, styles = false }) => (
  <h3
    css={css`
      font-weight: normal;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
        "Helvetica Neue", sans-serif;
      ${styles};
    `}
  >
    {children}
  </h3>
);
