import { lazy } from "react";
import Home from "./routes/home";
import Message from "./routes/message";
import Settings from "./routes/settings";

export const MenuItems = {
  home: {
    component: lazy(() =>
      import(
        /* webpackChunkName: "components" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        "./routes/home"
      )
    ),
    icon: () => "Home",
    show: () => true,
    name: "Home",
    key: "home",
    tooltip: "InContext home",
    top: true,
  },
  messages: {
    component: lazy(() =>
      import(
        /* webpackChunkName: "components" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        "./routes/message"
      )
    ),
    icon: (item) => "InboxCheck",
    show: () => Office.context.mailbox,
    name: "Filing",
    key: "messages",
    tooltip: "Filing",
    top: true,
  },

  /**  BOTTOM **/

  settings: {
    component: lazy(() =>
      import(
        /* webpackChunkName: "components" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        "./routes/settings"
      )
    ),
    icon: () => "Settings",
    show: () => true,
    name: "settings",
    key: "settings",
    tooltip: "Settings",
    bottom: true,
  },

  news: {
    component: lazy(() =>
      import(
        /* webpackChunkName: "components" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        "./components/news"
      )
    ),
    icon: () => "Giftbox",
    show: () => true,
    name: "news",
    key: "news",
    tooltip: "Whats new?",
  },

  help: {
    component: lazy(() =>
      import(
        /* webpackChunkName: "components" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        "./routes/help"
      )
    ),
    icon: () => "Lifesaver",
    show: () => false,
    name: "help",
    key: "help",
    tooltip: "Help you'll find here",
    bottom: true,
  },

  info: {
    component: lazy(() =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "components" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        "./routes/info"
      )
    ),
    icon: () => "Info",
    show: () => true,
    name: "info",
    key: "info",
    tooltip: "Info",
    bottom: true,
  },

  profile: {
    component: lazy(() =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "components" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        "./routes/profile"
      )
    ),
    icon: () => "User",
    show: () => true,
    name: "profile",
    key: "profile",
    route: "profile",
    tooltip: "Profile",
  },

  signout: {
    component: lazy(() =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "components" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        "./routes/signout"
      )
    ),
    icon: () => "Info",
    show: () => true,
    name: "signout",
    key: "signout",
  },
};
