import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-hot-loader/patch";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { Progress } from "./UI";
import { Login } from "./routes/login";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { SessionProvider } from "./contexts/SessionContext";
import { PluginsProvider } from "./contexts/PluginsContext";
import OfficeContext from "./contexts/OfficeContext";
import { firebaseConfig } from "./config/firebase.config";
import { AuthCheck, FirebaseProvider } from "./firebase/FirebaseProvider";
import "./sass/main.scss";
import LogRocket from "logrocket";
import { LogRocketConfig } from "./config/logrocket.config";

// globally configure MSAL
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/msalConfig";

let msalInstance = new PublicClientApplication(msalConfig);

const refreshMsalInstance = () => {
  console.log("Refreshing MSAL");
  let newMsalInstance = new PublicClientApplication(msalConfig);

  console.log(msalInstance);

  console.log(newMsalInstance === msalInstance);
  msalInstance = newMsalInstance;
};

initializeIcons();
LogRocket.init(LogRocketConfig.key, {
  release: "0.1.0",
  console: {
    isEnabled: {
      log: false,
      debug: false,
      error: true,
      info: true,
    },
    shouldAggregateConsoleErrors: true,
  },
});

/* global window, document, Office */
Office.onReady((officeHost) => {
  officeHost.host && document.body.classList.add(officeHost.host);
  officeHost.platform && document.body.classList.add(officeHost.platform);
  officeHost.displayLanguage = Office.context.displayLanguage;
  Office.context.isDialog && document.body.classList.add("isDialog");

  const isOffice = officeHost.host === "Outlook" && !Office.context.isDialog;

  ReactDOM.render(
    <FirebaseProvider config={firebaseConfig} suspense>
      <OfficeContext.Provider value={{ isOffice, officeHost, refreshMsalInstance }}>
        {isOffice && (!Office.context || !Office.context.mailbox) && <Progress message={"Connecting to Outlook"} />}
        {isOffice && Office.context.mailbox && (
          <AuthCheck fallback={<Login />}>
            <MsalProvider instance={msalInstance}>
              <SessionProvider>
                <PluginsProvider>
                  {(!isOffice || (isOffice && Office.context && Office.context.mailbox)) && (
                    <React.Suspense fallback={<Progress message={"Loading account data"} />}>
                      <Router>
                        <App />
                      </Router>
                    </React.Suspense>
                  )}
                </PluginsProvider>
              </SessionProvider>
            </MsalProvider>
          </AuthCheck>
        )}
      </OfficeContext.Provider>
    </FirebaseProvider>,
    document.getElementById("indexContainer")
  );
}).then((r) => {
  console.log(r);
});

if (module.hot) {
  module.hot.accept();
}
