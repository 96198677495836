import React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import styled from "@emotion/styled";

const LoaderSection = styled.div`
  text-align: center;
  width: 100%;
  animation: ease 2s;
  animation-duration: 0.5s;
  margin-top: 10%;
  font-size: 1.2rem;
`;

const HeroText = styled.h1`
  letter-spacing: 0.5rem;
  font-size: 1.8rem;

  & > span {
    display: block;
    font-size: 1.2rem;
    text-transform: lowercase;
    letter-spacing: 0.4rem;
  }
`;

/**
 *
 * @param {integer} props.delay time in milliseconds for the progress to show
 * @param {string} props.message string to show as loading info
 * @param {boolean} props.showSpinner = show a spinning animation
 * @param {boolean} props.showLogo = show logo
 */
export const Progress = (props) => {
  const [ready, setReady] = React.useState(false);

  const {
    logo = "/assets/logo-filled.png",
    message = "loading...",
    title = "InContext",
    claim = "",
    showSpinner = true,
    showLogo = true,
    size = 70,
    delay = 0,
  } = props;

  // allow for a delay to show the loader
  React.useEffect(() => {
    const showTimeout = setTimeout(() => setReady(true), delay);

    return () => {
      clearTimeout(showTimeout);
    };
  }, []);

  return (
    ready && (
      <LoaderSection className="ms-welcome__progress">
        {showLogo && <img width={size} height={size} src={logo} alt={title} title={title} />}

        {title && (
          <HeroText className="ms-fontSize-su ms-fontWeight-light ms-fontColor-neutralPrimary">
            {title}
            <span>{claim}</span>
          </HeroText>
        )}

        {showSpinner && <Spinner type={SpinnerSize.large} label={message} />}
      </LoaderSection>
    )
  );
};
